import { Component, OnInit } from '@angular/core';
import { PopoverController } from "@ionic/angular";
import {  Router } from '@angular/router';

@Component({
  selector: 'app-mypop',
  templateUrl: './mypop.component.html',
  styleUrls: ['./mypop.component.scss'],
})
export class MypopComponent {

  persoon: any;

  constructor(private router: Router,
    private popover: PopoverController
  ) { }

  ngOnInit() {}

  ClosePopover() {
    this.popover.dismiss();
  }

  trial(){
    this.router.navigateByUrl('/freetrial',{ skipLocationChange: true });
  }
  support(){
    this.router.navigateByUrl('/support',{ skipLocationChange: true });
  }

  policies(){
    this.router.navigateByUrl('/policies',{ skipLocationChange: true });
  }

}
