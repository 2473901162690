import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PopupService } from './services/popup.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  brokenImageUrl = 'https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/imagenes%2Flogo-menu.svg?alt=media&token=7dbdbca8-af77-45d2-b09d-195ddca08f88';
  brokenImageUrl2 = 'https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/imagenes%2Fusa-flag.svg?alt=media&token=4e77c9f5-1509-4251-a3d0-40244c52696c';
  brokenImageUrl3 = 'https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/imagenes%2Fbra-flag.svg?alt=media&token=f40951f0-0ddc-44e9-8c91-c1509ae9ac75';


  navigate : any;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private popupService: PopupService
  ) {
    this.sideMenu();
    this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  doSomethingOnError(event:any) {
    event.target.src = 'https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/imagenes%2Flogo-menu.svg?alt=media&token=7dbdbca8-af77-45d2-b09d-195ddca08f88'
}
  doSomethingOnError2(event:any) {
    event.target.src = 'https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/imagenes%2Fusa-flag.svg?alt=media&token=4e77c9f5-1509-4251-a3d0-40244c52696c'
  }
  doSomethingOnError3(event:any) {
    event.target.src = 'https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/imagenes%2Fbra-flag.svg?alt=media&token=f40951f0-0ddc-44e9-8c91-c1509ae9ac75'
  }
  sideMenu()
  {
    this.navigate =
    [
      {
        title : "Home",
        url   : "/",
        icon  : "home"
      },
      {
        title : "Our courses",
        url   : "/general-spanish",
        icon  : "school"
      },
      {
        title : "Who we are",
        url   : "/who-we-are",
        icon  : "newspaper"
      },
      {
        title : "For organizations",
        url   : "/for-organizations",
        icon  : "ribbon"
      },
      {
        title : "Resources",
        url   : "/resources",
        icon  : "bookmarks"
      },
      {
        title : "Contact",
        url   : "/contact",
        icon  : "call"
      },
      
      // {
      //   title : "Academics",
      //   url   : "/academics",
      //   icon  : "bookmarks"
      // },
      // {
      //   title : "Experience",
      //   url   : "/experience",
      //   icon  : "laptop"
      // },
      // {
      //   title : "About us",
      //   url   : "/aboutus",
      //   icon  : "ribbon"
      // },
      // {
      //   title : "Resources",
      //   url   : "/community",
      //   icon  : "file-tray-stacked"
      // },
      // {
      //   title : "Contact us",
      //   url   : "/contacto",
      //   icon  : "call"
      // },
      // {
      //   title : "Terms & conditions",
      //   url   : "/terminos",
      //   icon  : "albums"
      // },
      // {
      //   title : "Privacy Statement",
      //   url   : "/aviso",
      //   icon  : "document"
      // },
    ]
  }

  login(){
    console.log("click");
    let url ='https://logincomligo.firebaseapp.com/';
    window.open(url);
  }

 instragram(){
  let url2 ='https://instagram.com/comligo?igshid=1nliio11iq60x';
  window.open(url2);
 }

 facebook(){
  let url3 ='https://www.facebook.com/ComligoSpanish/';
  window.open(url3);
 }

 youtube(){
  let url4 ='https://m.youtube.com/channel/UCHlCaq1jxzQhy6A5SqukthA/featured';
  window.open(url4);
 }

 linkedin(){
  let url5 ='https://www.linkedin.com/company/learnspanishonlinecomligo';
  window.open(url5);
 }

 openIframePopup() {
  this.popupService.openPopup(); 
 }

}
