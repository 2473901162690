import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PopupService } from '../services/popup.service';

interface ChatMessage {
  message: string;
  timestamp: number;
  type: 'sent' | 'received'; // Agregamos el atributo 'type' para distinguir entre mensajes enviados y recibidos
}

declare var window: any;

@Component({
  selector: 'app-iframe-popup',
  templateUrl: './iframe-popup.page.html',
  styleUrls: ['./iframe-popup.page.scss'],
})
export class IframePopupPage implements OnInit {
  chatlogId: string | undefined;
  allMessages: ChatMessage[] = [];
  myForm: FormGroup;

  constructor(
    private popupService: PopupService,
    private formBuilder: FormBuilder
  ) { 
    this.myForm = this.formBuilder.group({
      userInput: [''],
    });
  }
  ngOnInit() {
    this.loadHubSpotScript();
  }

  sendMessage() {
    const message = this.myForm.get('userInput').value;

    if (message) {
      const sentMessage: ChatMessage = { message, timestamp: Date.now(), type: 'sent' };
      this.allMessages.push(sentMessage);
      this.myForm.get('userInput').setValue('');

      if (this.chatlogId) {
        this.sendMessageWithChatlogId(message);
      } else {
        this.sendMessageWithoutChatlogId(message);
      }
    }
  }

  sendMessageWithoutChatlogId(message: string) {
    this.popupService.sendMessageToChatbot(message)
      .subscribe((response: any) => {
        if (response && response.response) {
          const receivedMessage: ChatMessage = { message: response.response, timestamp: Date.now(), type: 'received' };
          this.allMessages.push(receivedMessage);
          this.chatlogId = response.chatlogId; // Actualizamos el chatlogId cuando recibimos una respuesta
        }
        this.allMessages = this.allMessages.sort((a, b) => b.timestamp - a.timestamp);
      }, (error) => {
        console.error('Error al recibir la respuesta del servidor:', error);
        // Manejar errores
      });
  }
  
  sendMessageWithChatlogId(message: string) {
    this.popupService.sendMessageToChatbot(message, this.chatlogId)
      .subscribe((response: any) => {
        if (response && response.response) {
          const receivedMessage: ChatMessage = { message: response.response, timestamp: Date.now(), type: 'received' };
          this.allMessages.push(receivedMessage);
          this.chatlogId = response.chatlogId;
        }
        this.allMessages = this.allMessages.sort((a, b) => b.timestamp - a.timestamp);
      }, (error) => {
        console.error('Error al recibir la respuesta del servidor:', error);
        // Manejar errores
      });
  }

  loadHubSpotScript() {
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/21846140.js'; // Reemplaza con la URL correcta
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.hsConversationsSettings = {
        loadImmediately: true,
        enableWelcomeMessage: true,
      };
    };
  }
}
