import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject,Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {
  private isOpenSubject = new BehaviorSubject<boolean>(false);
  isOpen$ = this.isOpenSubject.asObservable();

  constructor(private http: HttpClient) { }

  openPopup() {
    this.isOpenSubject.next(true);
  }

  closePopup() {
    this.isOpenSubject.next(false);
  }

  sendMessageToChatbot(message: string,chatlogId?: string): Observable<any> {
    const postData = {
      chatbotId: 'clnymwfyr011pl72109lt2nas', // Ensure this configuration is set in your environment or server
      question: message,
      context: 'I want a Spanish class',
      chatlogId: chatlogId
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http.post('https://restserver-323001.uw.r.appspot.com/api/bot/incoming', postData, { headers });
  }
}
