import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
//import { RouterModule, Router } from '@angular/router';
//////////////////////
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
///////////mensaje
import { MessageService } from './services/message.service';
import { HttpClientModule,HttpClient } from '@angular/common/http';
import { SimpleSmoothScrollModule } from 'ng2-simple-smooth-scroll';
////
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './lowerCaseUrlSerializer';
/////firebase
///////popover
import { MypopComponent} from "./popovers/mypop/mypop.component";

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IframePopupPage } from './iframe-popup/iframe-popup.page';

@NgModule({
  declarations: [AppComponent,MypopComponent,IframePopupPage],
  entryComponents: [MypopComponent],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
            FontAwesomeModule,
            HttpClientModule,
            SimpleSmoothScrollModule,            
            AngularFireModule.initializeApp(environment.firebase),
            AngularFirestoreModule,
            BrowserAnimationsModule,
            ReactiveFormsModule
          ],
  providers: [
    StatusBar,
    SplashScreen,
    MessageService,
    HttpClient,

    {
      provide:UrlSerializer,
      useClass:LowerCaseUrlSerializer
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

  constructor(library: FaIconLibrary) { 
		library.addIconPacks(fas, fab, far);
	}
}
