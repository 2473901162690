import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    loadChildren: './home/home.module#HomePageModule'
  },
  {
    path: 'resources',
    loadChildren: './resources/resources.module#ResourcesPageModule'
  },
  {
    path: 'teachers',
    loadChildren: './teachers/teachers.module#TeachersPageModule'
  },
  {
    path: 'academicteam',
    loadChildren: './academicteam/academicteam.module#AcademicteamPageModule'
  },
  {
    path: 'support',
    loadChildren: './support/support.module#SupportPageModule'
  },
  {
    path: 'events',
    loadChildren: './events/events.module#EventsPageModule'
  },
  {
    path: 'ime',
    loadChildren: './ime/ime.module#ImePageModule'
  },
  {
    path: 'topic-mental',
    loadChildren: './topic-mental/topic-mental.module#TopicMentalPageModule'
  },
  {
    path: 'topic-online',
    loadChildren: './topic-online/topic-online.module#TopicOnlinePageModule'
  },
  {
    path: 'topic-hispanic-culture',
    loadChildren: './topic-hispanic-culture/topic-hispanic-culture.module#TopicHispanicCulturePageModule'
  },
  {
    path: 'contact',
    loadChildren: './contact/contact.module#ContactPageModule'
  },
  {
    path: 'who-we-are',
    loadChildren: './who-we-are/who-we-are.module#WhoWeArePageModule'
  },
  {
    path: 'for-organizations',
    loadChildren: './for-organizations/for-organizations.module#ForOrganizationsPageModule'
  },
  {
    path: 'spanish-for-adults',
    loadChildren: './spanish-for-adults/spanish-for-adults.module#SpanishForAdultsPageModule'
  },
  {
    path: 'general-spanish',
    loadChildren: './general-spanish/general-spanish.module#GeneralSpanishPageModule'
  },
  {
    path: 'spanish-for-business',
    loadChildren: './spanish-for-business/spanish-for-business.module#SpanishForBusinessPageModule'
  },
  {
    path: 'spanish-for-young-learners',
    loadChildren: './spanish-for-young-learners/spanish-for-young-learners.module#SpanishForYoungLearnersPageModule'
  },
  {
    path: 'diversity-training',
    loadChildren: './diversity-training/diversity-training.module#DiversityTrainingPageModule'
  },
  {
    path: 'online-development',
    loadChildren: './online-development/online-development.module#OnlineDevelopmentPageModule'
  },
  {
    path: 'spanish-for-tourism',
    loadChildren: './spanish-for-tourism/spanish-for-tourism.module#SpanishForTourismPageModule'
  },
  {
    path: 'spanish-for-health-care',
    loadChildren: './spanish-for-health-care/spanish-for-health-care.module#SpanishForHealthCarePageModule'
  },
  {
    path: 'written-expression',
    loadChildren: './written-expression/written-expression.module#WrittenExpressionPageModule'
  },
  {
    path: 'oral-expression-interaction',
    loadChildren: './oral-expression-interaction/oral-expression-interaction.module#OralExpressionInteractionPageModule'
  },
  {
    path: 'auditive-understanding',
    loadChildren: './auditive-understanding/auditive-understanding.module#AuditiveUnderstandingPageModule'
  },
  {
    path: 'preparation-dele',
    loadChildren: './preparation-dele/preparation-dele.module#PreparationDelePageModule'
  },
  {
    path: 'preparation-siele',
    loadChildren: './preparation-siele/preparation-siele.module#PreparationSielePageModule'
  },
  {
    path: 'general-spanish-for-children',
    loadChildren: './general-spanish-for-children/general-spanish-for-children.module#GeneralSpanishForChildrenPageModule'
  },
  {
    path: 'general-spanish-for-teenagers',
    loadChildren: './general-spanish-for-teenagers/general-spanish-for-teenagers.module#GeneralSpanishForTeenagersPageModule'
  },
  {
    path: 'hispanic-culture-civilization',
    loadChildren: './hispanic-culture-civilization/hispanic-culture-civilization.module#HispanicCultureCivilizationPageModule'
  },
  {
    path: 'spanish-summer-camp',
    loadChildren: './spanish-summer-camp/spanish-summer-camp.module#SpanishSummerCampPageModule'
  },
  {
    path: 'written-expression-creative', ///1.1.2.4
    loadChildren: './written-expression-creative/written-expression-creative.module#WrittenExpressionCreativePageModule'
  },
  {
    path: 'oral-expression', ///1.1.2.5
    loadChildren: './oral-expression/oral-expression.module#OralExpressionPageModule'
  },
  {
    path: 'auditive-understanding-course',///1.1.2.6
    loadChildren: './auditive-understanding-course/auditive-understanding-course.module#AuditiveUnderstandingCoursePageModule'
  },
  {
    path: 'spanish-for-diversity-global',
    loadChildren: './spanish-for-diversity-global/spanish-for-diversity-global.module#SpanishForDiversityGlobalPageModule'
  },
  {
    path: 'culture-civilization',
    loadChildren: './culture-civilization/culture-civilization.module#CultureCivilizationPageModule'
  },
  {
    path: 'diversity-intercultural-awareness',
    loadChildren: './diversity-intercultural-awareness/diversity-intercultural-awareness.module#DiversityInterculturalAwarenessPageModule'
  },
  {
    path: 'teacher-training',
    loadChildren: './teacher-training/teacher-training.module#TeacherTrainingPageModule'
  },
  {
    path: 'online-development-teachers',
    loadChildren: './online-development-teachers/online-development-teachers.module#OnlineDevelopmentTeachersPageModule'
  },
  {
    path: 'online-development-students',
    loadChildren: './online-development-students/online-development-students.module#OnlineDevelopmentStudentsPageModule'
  },
  {
    path: 'blog',
    loadChildren: './blog/blog.module#BlogPageModule'
  },
  {
    path: 'ebooks',
    loadChildren: './ebooks/ebooks.module#EbooksPageModule'
  },
  {
    path: 'policies',
    loadChildren: './policies/policies.module#PoliciesPageModule'
  },
  {
    path: 'freetrial',
    loadChildren: './freetrial/freetrial.module#FreetrialPageModule'
  },
  {
    path: 'official-k12-digital-learning-provider',
    loadChildren: './official-k12-digital-learning-provider/official-k12-digital-learning-provider.module#OfficialK12DigitalLearningProviderPageModule'
  },
  {
    path: 'new-hampshire-learn-everywhere-program',
    loadChildren: './new-hampshire-learn-everywhere-program/new-hampshire-learn-everywhere-program.module#NewHampshireLearnEverywhereProgramPageModule'
  },
  {
    path: 'nevada-digital-learning-provider',
    loadChildren: './nevada-digital-learning-provider/nevada-digital-learning-provider.module#NevadaDigitalLearningProviderPageModule'
  },
  {
    path: 'arkansas-digital-learning-provider',
    loadChildren: './arkansas-digital-learning-provider/arkansas-digital-learning-provider.module#ArkansasDigitalLearningProviderPageModule'
  },
  {
    path: 'corporate-brochure',
    loadChildren: './corporate-brochure/corporate-brochure.module#CorporateBrochurePageModule'
  },
  {
    path: 'privacy-statement',
    loadChildren: './privacy-statement/privacy-statement.module#PrivacyStatementPageModule'
  },
  {
    path: 'accessibility',
    loadChildren: './accessibility/accessibility.module#AccessibilityPageModule'
  },
  {
    path: 'non-discrimination',
    loadChildren: './non-discrimination/non-discrimination.module#NonDiscriminationPageModule'
  },
  {
    path: 'blog-details/:id',
    loadChildren: './blog-details/blog-details.module#BlogDetailsPageModule'
  },
  {
    path: 'collateral-details/:id',
    loadChildren: './collateral-details/collateral-details.module#CollateralDetailsPageModule'
  },
  {
    path: 'corporate-brochure-all',
    loadChildren: './corporate-brochure-all/corporate-brochure-all.module#CorporateBrochureAllPageModule'
  },
  {
    path: 'christmas2022',
    loadChildren: './christmas2022/christmas2022.module#Christmas2022PageModule'
  },
  {
    path: 'ime-esp',
    loadChildren: './ime-esp/ime-esp.module#ImeEspPageModule'
  },
  {
    path: 'ime-eng',
    loadChildren: './ime-eng/ime-eng.module#ImeEngPageModule'
  },
  {
    path: 'spanishweek2023',
    loadChildren: './spanish-weekend/spanish-weekend.module#SpanishWeekendPageModule'
  },
  {
    path: 'corporate-brochure-2',
    loadChildren: './corporate-brochure-two/corporate-brochure-two.module#CorporateBrochureTwoPageModule'
  },
  {
    path: 'corporate-brochure-3',
    loadChildren: './corporate-brochure-three/corporate-brochure-three.module#CorporateBrochureThreePageModule'
  },
  {
    path: 'corporate-brochure-4',
    loadChildren: './corporate-brochure-four/corporate-brochure-four.module#CorporateBrochureFourPageModule'
  },
  {
    path: 'spanish-for-k12-students',
    loadChildren: './spanish-for-k12-students/spanish-for-k12-students.module#SpanishForK12StudentsPageModule'
  },
  {
    path: 'introduction-to-spanish-cultural-diversity',
    loadChildren: './introduction-to-spanish-cultural-diversity/introduction-to-spanish-cultural-diversity.module#IntroductionToSpanishCulturalDiversityPageModule'
  },
  {
    path: 'spanish-ib-programs',
    loadChildren: './spanish-ib-programs/spanish-ib-programs.module#SpanishIbProgramsPageModule'
  },
  {
    path: 'pd-for-teachers',
    loadChildren: './pd-for-teachers/pd-for-teachers.module#PdForTeachersPageModule'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes,  { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
