import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor( private http: HttpClient) { }
  sendMessage(body) {
   // return this.http.post('http://localhost:5000/emaildaemon-dbfab/us-central1/mailer', body);
    //return this.http.post(' /emailMessage', body);
    // return this.http.post('https://us-central1-emaildaemon-95512.cloudfunctions.net/mailer', body);
    return this.http.post('https://restserver-323001.uw.r.appspot.com/api/webpage/contact', body);
  }

}
